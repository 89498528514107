// @codekit-prepend "../bower_components/jquery/dist/jquery.min.js";
// @codekit-prepend "../bower_components/slick-carousel/slick/slick.min.js";
// @codekit-prepend "../bower_components/magnific-popup/dist/jquery.magnific-popup.min.js";



$(document).ready(function(){
  $('div.slidergewerbe').slick({
    dots: true,
    prevArrow: '<button type="button" class="slick-prev"><img src="/sites/all/themes/glashus/images/icon-l.svg" /></button>',
    nextArrow: '<button type="button" class="slick-next"><img src="/sites/all/themes/glashus/images/icon-r.svg" /></button>'
  });

  $("section.intro .readmore span").click(function() {
    $(this).toggleClass("active");
    $(".details").slideToggle();
  });

  $('.details').magnificPopup({
		delegate: 'a',
		type: 'image',
		tLoading: 'Lade Bild #%curr%...',
		mainClass: 'mfp-img-mobile',
		gallery: {
			enabled: true,
			navigateByImgClick: true,
			preload: [0,1]
		}
	});

  $('div.sliderbesuch').slick({
    dots: false,
    arrows: true,
    prevArrow: '<button type="button" class="slick-prev"><img src="/sites/all/themes/glashus/images/icon-l-w.svg" /></button>',
    nextArrow: '<button type="button" class="slick-next"><img src="/sites/all/themes/glashus/images/icon-r-w.svg" /></button>',
    responsive: [
      {
        breakpoint: 640,
        settings: {
          prevArrow: '<button type="button" class="slick-prev"><img src="/sites/all/themes/glashus/images/icon-l.svg" /></button>',
          nextArrow: '<button type="button" class="slick-next"><img src="/sites/all/themes/glashus/images/icon-r.svg" /></button>',
        }
      }
    ]



  });

  $('.lightbox').magnificPopup({
		type: 'inline',
		preloader: false,
    closeBtnInside: true
  });

  $(".anchorlink").click(function(e) {
    e.preventDefault();
    var anchorlink = $(this).attr("href");
    $('html, body').animate({
        scrollTop: $(anchorlink).offset().top
    }, 1000);
  });

  $("footer span").click(function(e) {
    e.preventDefault();

    $('html, body').animate({
        scrollTop: 0
    }, 1000);
  });



});


google.maps.event.addDomListener(window, 'load', init);

function init() {
    var mapOptions = {
        zoom: 11,
        center: new google.maps.LatLng(47.718179, 8.910854),

        styles: [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}]
    };

    var mapElement = document.getElementById('map');
    var map = new google.maps.Map(mapElement, mapOptions);
    var marker = new google.maps.Marker({
        position: new google.maps.LatLng(47.718179, 8.910854),
        map: map
    });
}
